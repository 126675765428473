<template lang="pug">
include ../../../configs/template
div.row.text-start
  div.col-sm-12.col-md-6
    +data-info('number','passport.number')
  div(v-if="passport.country").col-sm-12.col-md-6
    +data-info('country', 'getDirectoryObject({ value: "country", id: passport.country}).value')
  div.col-sm-12.col-md-6
    +data-info('dateIssue', 'getDateFormat(passport.date_start)')
  div.col-sm-12.col-md-6
    +data-info('dateTermValid', 'getDateFormat(passport.date_end)')
  div.col-sm-12.col-md-6
    +data-info('nameUK', 'fullNameUkr')
  div.col-sm-12.col-md-6
    +data-info('nameEN', 'fullNameEng')
  div(v-if="checkAccess('document-author-view')").col-sm-12.col-md-6
    +data-info('createDate', 'passport.created_at')
  div.col-sm-12.col-md-6
    +data-info-status('status', 'getDirectoryObject({ value: "statuses", id: passport.status_document })[nameLang]', 'getStatus(passport.status_document)')
  div.col-12
    div(v-if="passport.comments?.length && [STATUSES_DOCUMENT_CONSTANTS.SAILOR.PASSPORTS.FOREIGN.REJECTED].includes(passport.status_document)").col-sm-12
      v-divider
      h5.p-0.m-0 {{$t('rejectionReasonStatement')}}:
      div(v-if="passport.comments?.length").col-sm-12
        div(v-for="comment in passport.comments" :key="comment.id").pa-2
          div(v-if="comment.additional_info.reason")
            +data-info('rejectionReason','getDirectoryObject({ value: "rejectionReasons", id: comment.additional_info.reason })[nameLang]')
          div(v-if="checkAccess('backOffice')").pt-2.py-3
            +data-info('createdBy', 'comment.created_by.name')
          div.pt-2.py-3
            +data-info('dateCreate', 'new Date(comment.created_by.date).toLocaleString()')
          div.pt-2.py-3
            +data-info('setComment', 'comment.comment')
</template>

<script>
import { checkAccess } from '@/mixins/permissions'
import { getStatus, getDateFormat } from '@/mixins/main'
import { STATUSES_DOCUMENT_CONSTANTS } from '@/configs/constants'
import { mapGetters, mapState } from 'vuex'
export default {
  name: 'SailorInternationalPassportInfo',
  props: {
    passport: { type: Object, default: () => ({}) }
  },
  data () {
    return {
      getStatus,
      getDateFormat,
      checkAccess,
      STATUSES_DOCUMENT_CONSTANTS
    }
  },
  computed: {
    ...mapGetters(['getDirectoryObject']),
    ...mapState({
      nameLang: state => state.main.lang === 'en' ? 'name_eng' : 'name_ukr'
    }),
    fullNameUkr () {
      return `${this.passport.last_name_ukr} ${this.passport.first_name_ukr} ${this.passport.middle_name_ukr}`
    },
    fullNameEng () {
      return `${this.passport.last_name_eng} ${this.passport.first_name_eng}`
    }
  }
}
</script>
